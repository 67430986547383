import React from 'react'
import { FaAngleDoubleRight } from 'react-icons/fa'
import { SiAdobeacrobatreader } from 'react-icons/si'
import { Link } from 'react-router-dom'
import MachineSpecs from '../Components/MachineComponent/MachineSpecs'
import specs from '../Data/UAEPaper.json'
import RelatedProducts from '../Components/MachineComponent/RelatedProducts'
import { Helmet } from 'react-helmet'
import Form1 from '../Components/MarketArea/Form1'
import Form2 from '../Components/MarketArea/Form2'

const TurkeyPaper = () => {
    return (
        <><Helmet>
            <title>Paper Cup Making Machine in Turkey | Nessco</title>
            <meta name="description" content="Nessco offers a wide range of paper cup making machine in Turkey for 40 years. Best Price. Get Quote Now!" />
            <meta name="keywords" content="paper cup making machine, paper cup machine, paper cup machine price, paper cup making machine near me, paper cup making machine price, paper cup, paper cup machine manufacturer, paper cup machine company, cup manufacturing machine" />
            <meta name="copyright" content="Copyright 2022 Nessco India" />
            <meta name="distribution" content="global" />
            <meta name="language" content="english" />
            <meta name="robots" content="index,follow" />
            <meta name="publisher" content="Nessco India" />
            <meta name="author" content="Nessco India" />
            <link rel="canonical" href="https://www.nesscoindia.com/turkey/paper-cup-making-machine-in-turkey/" />

        </Helmet>
            <section className="intromachine">
                <div className="intromachinecompo">
                    <div className="intromachineleft">
                        <h1 className="intromachineheading">Paper Cup Making <br /><span>Machine In Turkey</span></h1>
                        <p className="intromachinedescription">
                            Nessco India is known for manufacturing and globally exporting various paper product forming machineries. With a strong functional position in this market for over 40 years, now we are backed by 5 Domestic Distributing Centers and 4 International Ones. Over all these years, we have spread our arms to almost every country to expand our business globally.
                        </p>
                    </div>
                    <Form1 />
                </div>
            </section>
            <section className="descriptionmachine">
                <div className="descriptionmachinecomp">
                    <div className="descriptionmachineleft">                    <img src={'https://nesscoindia.com/Assets/images/resource/fully-automatic-paper-cup-machine.webp'} alt="" />

                        <h3 className="descriptionmachineleftdata">Paper Cup Making <span>Machine In Turkey</span></h3>
                        <p className="descriptionmachineleftcontent">Nessco India top-quality range of <Link>disposable paper cup making machines in turkey</Link>. Which is multi-station programmed, fit for creating a an unbelievable number of paper cups all at once. With the continuous automatic systems like fixing, oil filling, warming, knurling, twisting, and dumping make it the ideal machine.
                            <br /><br />
                            Nessco <Link> paper cup making machines</Link> is ideal for creating paper drinking cups, market cups, promoting cups, and some more. In turkey or globally it is generally utilized for hot beverages and refreshments like cold beverages and buttermilk. Additionally, our customers can have these machines at at the budget-friendly price.</p> <br /><br />
                    </div>
                    <div className="descriptionmachineright">
                        <div className="descriptionsuggestions">
                            {
                                specs.Suggestions.map((obj, key) => (
                                    <Link key={key} to={obj.suggestionlink} className="descriptionsuggestion">
                                        <FaAngleDoubleRight />{obj.name}
                                    </Link>

                                ))
                            }
                        </div>
                        <Form2 />
                        <div className="downloadbrochures">
                            <h3 className="downloadbrochuresheading">Download Brochures</h3>
                            <p className="downloadbrochuressubheading">Download Our Catalogue to know more about machines</p>
                            <div className="brochurecontent">
                                <div className="brochurelogo">
                                    <SiAdobeacrobatreader />
                                </div>
                                <div className="brochuredescription">
                                    <a href='/' className="brochuretitle">Nessco India Catalogue</a>
                                    <a href='/' className="brochuresubtitle">Download Now</a>
                                </div>
                            </div>
                            <div className="brochurecontent">
                                <div className="brochurelogo">
                                    <SiAdobeacrobatreader />
                                </div>
                                <div className="brochuredescription">
                                    <a href='/' className="brochuretitle">International Catalogue</a>
                                    <a href='/' className="brochuresubtitle">Download Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <MachineSpecs labels={specs.MachineSpecsLabels} data={specs.MachineSpecs} />
            <RelatedProducts data={specs.RelatedProducts} />
        </>
    )
}

export default TurkeyPaper
